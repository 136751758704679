import React from 'react'

export const SadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
    >
      <g clip-path="url(#a)">
        <path
          fill="#FFB800"
          d="M91.554 41.892c0-3.83-.46-7.55-1.32-11.115a44.833 44.833 0 0 0-9.986-13.513 47.057 47.057 0 0 0-25.937-6.636c-26.104.951-46.494 22.885-45.543 48.989.14 3.819.733 7.513 1.719 11.038a44.826 44.826 0 0 0 8.086 10.953 47.062 47.062 0 0 0 25.684 7.58c26.12 0 47.297-21.175 47.297-47.296ZM66.78 33.446a5.406 5.406 0 1 1-.001 10.811 5.406 5.406 0 0 1 0-10.811Zm-33.447 0a5.406 5.406 0 1 1 0 10.811 5.406 5.406 0 0 1 0-10.811ZM29.716 72.77l-4.268-3.318c7.244-9.317 19.39-13.776 30.942-11.362 7.183 1.503 13.633 5.538 18.161 11.362l-4.267 3.318a25.699 25.699 0 0 0-15-9.388C45.736 61.385 35.7 65.07 29.715 72.77Z"
        />
        <path
          fill="#DB9800"
          d="M90.234 30.776a47.369 47.369 0 0 1 1.32 11.115c0 26.122-21.176 47.298-47.297 47.298a47.064 47.064 0 0 1-25.684-7.581C26.641 89.629 37.751 94.594 50 94.594c24.59 0 44.595-20.005 44.595-44.594 0-6.88-1.567-13.4-4.36-19.224Z"
        />
        <path
          fill="#FFDA7B"
          d="M54.311 10.627a47.059 47.059 0 0 1 25.937 6.637C72.293 9.908 61.663 5.405 50 5.405 25.41 5.405 5.405 25.41 5.405 50c0 7.449 1.839 14.476 5.082 20.655a47.372 47.372 0 0 1-1.719-11.038c-.95-26.105 19.439-48.038 45.543-48.99Z"
        />
        <path
          fill="#050505"
          d="M50 0C22.43 0 0 22.43 0 50s22.43 50 50 50 50-22.43 50-50S77.57 0 50 0Zm0 94.594c-12.25 0-23.36-4.964-31.427-12.986a44.836 44.836 0 0 1-8.086-10.953A44.319 44.319 0 0 1 5.405 50C5.405 25.41 25.41 5.405 50 5.405c11.663 0 22.293 4.503 30.248 11.86a44.831 44.831 0 0 1 9.986 13.512A44.328 44.328 0 0 1 94.594 50c0 24.59-20.004 44.594-44.594 44.594Z"
        />
        <path
          fill="#050505"
          d="M55.284 63.382a25.697 25.697 0 0 1 15 9.388l4.267-3.317a31.11 31.11 0 0 0-18.16-11.362c-11.553-2.415-23.7 2.044-30.943 11.362l4.268 3.317c5.985-7.699 16.022-11.385 25.568-9.388ZM33.333 44.256a5.405 5.405 0 1 0 0-10.81 5.405 5.405 0 0 0 0 10.81ZM66.78 44.256a5.405 5.405 0 1 0 0-10.81 5.405 5.405 0 0 0 0 10.81Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h100v100H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SadIcon
