const url = {
  me: '/me',
  user: '/users',
  coursesAndCertificates: '/users/course-certificate/count',
  userAvatar: '/users/avatar',
  makeContactDefault: '/users/contacts',
  devices: '/users/devices',
  history: '/users/activities',
}

export default url
